import request from '../utils/request'
import config from './config'

/**
 * 补贴配置
 */
// 补贴配置--服务对象类别列表
export function getListData(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceObject/pageList`,
    method: 'post',
    data,
  })
}

// 补贴配置--服务对象类别新增
export function addService(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceObject/saveServiceObject`,
    method: 'post',
    data,
  })
}
// 补贴配置--编辑
export function editService(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceObject/updateServiceObject`,
    method: 'post',
    data,
  })
}

// 补贴配置--删除
export function delitem(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceObject/deleteServiceById`,
    method: 'get',
    params,
  })
}

// 补贴配置--查看
export function getDetail(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceObject/selectServiceById`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程-列表
export function getProcessList(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/pageList`,
    method: 'post',
    data,
  })
}

// 补贴配置--流程-新增
export function addProcess(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/saveSubsidyProcess`,
    method: 'post',
    data,
  })
}

// 补贴配置--流程-类别
export function getselectServiceObject(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/selectServiceObject`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程-选择角色
export function selectAllRole(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/selectAllRole`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程-新增附件
export function saveEnclosure(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionEnclosure/saveEnclosure`,
    method: 'post',
    data,
  })
}

// 补贴配置--流程-附件列表
export function selectEnclosure(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionEnclosure/selectEnclosure`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程-编辑附件
export function updateEnclosure(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionEnclosure/updateEnclosure`,
    method: 'post',
    data,
  })
}

// 补贴配置--流程-删除附件
export function deleteEnclosure(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionEnclosure/deleteEnclosure`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程-详情
export function selectById(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/selectById`,
    method: 'get',
    params,
  })
}

// 补贴配置--流程--编辑
export function updateSubsidyProcess(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/updateSubsidyProcess`,
    method: 'post',
    data,
  })
}

// 补贴配置--流程--删除
export function deleteById(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionSubsidyProcess/deleteById`,
    method: 'get',
    params,
  })
}

// 补贴配置--取消时清空附件表中多余信息
export function deleteEnclosureSome(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionEnclosure/deleteEnclosureSome`,
    method: 'get',
    params,
  })
}
























